var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "initial_page"
  }, [_c('div', {
    staticClass: "main_page"
  }, [_vm._m(0), _c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "save_device"
  }, [_c('h3', [_vm._v("Would you like to save this device?")]), _c('p', [_vm._v(" Save this device as a recognized device if its trusted. Please note if you select No, then this device (Computer) will not be saved and you will be asked to verify account for future logins from this device(Computer) ")]), _c('b-form-group', [_c('b-form-radio', {
    attrs: {
      "name": "radios",
      "value": "1"
    },
    model: {
      value: _vm.trusted_device,
      callback: function callback($$v) {
        _vm.trusted_device = $$v;
      },
      expression: "trusted_device"
    }
  }, [_vm._v("Yes (This is a Trusted Device)")]), _c('b-form-radio', {
    attrs: {
      "name": "radios",
      "value": "0"
    },
    model: {
      value: _vm.trusted_device,
      callback: function callback($$v) {
        _vm.trusted_device = $$v;
      },
      expression: "trusted_device"
    }
  }, [_vm._v("No (This is not a Trusted Device)")])], 1), _c('button', {
    attrs: {
      "disabled": _vm.verifyLoader
    },
    on: {
      "click": _vm.saveBrowser
    }
  }, [_vm.verifyLoader == true ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.verificationText) + " ")], 1)], 1)])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "login_save_welcome"
  }, [_c('h1', [_vm._v("You are Logged In")]), _c('p', [_vm._v(" Hurray!! You have logged in successfully into your account. Save this device if its trusted. ")])]);
}]

export { render, staticRenderFns }